<template>
    <div>
        <div v-if="!loading && order != null && error.length == 0" style="margin:-12px;">
            <v-sheet style="height:40vh;" color="accent">
                <v-container fill-height>
                    <v-card flat color="transparent">
                        <v-icon color="white" class="pl-4" size="80">mdi-check</v-icon>
                        <div>
                            <v-card-subtitle class="white--text pb-0" style="font-weight:300;font-size:1.2rem;">Hola {{ customer.firstName }} {{ customer.lastName }},</v-card-subtitle>
                            <v-card-title class="display-2 white--text pt-0">Gracias por tu orden</v-card-title>
                        </div>
                    </v-card>
                </v-container>
            </v-sheet>
            <div class="pa-3">
                <v-subheader class="pl-0">Detalles de la orden:</v-subheader>
                <div class="subtitle-1" style="font-weight: 500">Número de orden: <span class="accent--text">{{ order.data.orderNumber.toUpperCase() }}</span></div>
                <div class="subtitle-1" style="font-weight: 500">Fecha: {{ formatDate( order.data.orderDate ) }}</div>
                <div class="subtitle-1" style="font-weight: 500">Total: {{ toMoney( order.data.total ) }}</div>

                <div class="mt-8 grey--text text--darken-2">
                    Este es el primer paso para lograr todas tus metas. Si sigues todos mis consejos empezarás a ver el cambios en varias semanas de haber comenzado.
                </div>
                <div class="mt-8 grey--text text--darken-2">
                    <div class="petu-green">Kenialyz Martínez</div>
                    <div class="petu-pink">Creadora del movimiento 'Petu Power'</div>
                </div>

                <v-divider class="my-8"></v-divider>

                <div>
                    <v-row>
                        <v-col cols="6" class="d-flex align-center">Síguenos en</v-col>
                        <v-col cols="6" class="d-flex align-center justify-end text-right">
                            <v-icon color="primary" size="50" @click="toInstagram()">mdi-instagram</v-icon>
                        </v-col>
                    </v-row>
                </div>

                <v-btn block depressed class="mt-4" color="primary font-weight-black no-uppercase" to="/suscripciones">Ver mis entrenamientos</v-btn>
            </div>
        </div>

        <v-container v-else-if="error.length > 0" fill-height class="pa-0" style="height:90vh;">
            <top-nav></top-nav>
            <div class="headline text-center grey--text text--darken-1" style="width:100%;">
                <v-icon size="64">mdi-alert-circle-outline</v-icon>
                <div>{{ error }}</div>
            </div>
            <bottom-nav style="margin:-12px;margin-bottom:0;"></bottom-nav>
        </v-container>
    </div>
</template>

<script>
import { _st } from '@/softech';
import { mapGetters } from 'vuex';
import { PetuOrder } from '../classes/order';
import moment from 'moment';
import TopNav from '@/components/TopNav';
import BottomNav from '@/components/BottomNav';

export default {
    props: {
        orderId: { type: String, default: '' }
    },
    data() {
        return {
            order   : null,
            error   : '',

            loading : true
        }
    },
    computed: {
        ...mapGetters({
            customer: 'auth/customer'
        })
    },
    async mounted() {
        if( _st.isNUE( this.orderId ) ) {
            this.error = 'Orden no existe';
            return
        }

        try {
            this.order = new PetuOrder();
            await this.order.load( this.orderId );
            
            if( this.order.data.customerId != this.customer.id ) {
                this.error = 'Orden pertenece a otro cliente';
                return; 
            }
        }
        catch(error) {
            console.log(error);
            this.error = error;
        }
        finally {
            this.loading = false;
        }
    },
    methods: {
        formatDate( val ) {
            return moment( val ).format( 'MMM DD, YYYY' );
        },
        toMoney( val ) {
            return _st.toMoney( val );
        },
        toInstagram() {
            window.open( 'http://www.instagram.com/petunia_anacleta', '_blank' );
        }
    },
    components: { TopNav, BottomNav }
}
</script>

<style lang="scss" scoped>

</style>